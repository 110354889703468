<template>
  <b-overlay :show="formShow" rounded="sm" no-fade class="mt-1">
    <b-card>
      <b-row class="mb-1">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col" v-if="taskId > 0">Task Edit</th>
              <th scope="col" v-else>Task Add</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <validation-observer ref="taskRules">
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Task" label-for="title">
                <validation-provider name="title" #default="{ errors }" rules="required">
                  <b-form-input id="title" type="text" v-model="taskValue.title"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label-for="role">
                <label>
                  User Role
                </label>
                <feather-icon
                  icon="AlertCircleIcon"
                  size="15"
                  v-b-tooltip.hover title="Task assignment in the 'Rota Builder' function will be limited to roles specified here. The limitations won't apply to manual changes in Rota. "
                  class="warning-button"
                  
                  />
                <validation-provider name="role" #default="{ errors }" rules="required">
                  <v-select v-model="taskValue.roles" :options="roleOptions" :reduce="(val) => val.name" label="name" multiple :clearable="false" :close-on-select="false" input-id="role" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" class="mt-1">
              <b-form-group label="Task Colour" label-for="color">
                <app-color-palette-vue v-if="!formShow" @update:selected-color="colorSelectedHandler" :row-number="16" :selected-color-data="taskValue.color.textColor" :selected-bg-color-data="taskValue.color.bgColor"></app-color-palette-vue>
              </b-form-group>
            </b-col>
          </b-row>

          <hr />

          <b-row>
            <b-col cols="12">
              <b-button v-if="taskId == null" type="submit" @click.prevent="formSubmitted" variant="primary" class="float-right">
                Save
              </b-button>
              <b-button v-else type="submit" @click.prevent="formSubmitted" variant="primary" class="float-right">
                Save Changes
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>
<script>
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from '../settingsStoreModule';
import vSelect from 'vue-select';
import router from '@/router';
import AppColorPaletteVue from '@/@core/layouts/components/AppColorPalette.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations';

export default {
  components: {
    vSelect,
    ToastificationContent,
    AppColorPaletteVue,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    const OBJECT_CODE_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(OBJECT_CODE_APP_STORE_MODULE_NAME)) store.registerModule(OBJECT_CODE_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(OBJECT_CODE_APP_STORE_MODULE_NAME)) store.unregisterModule(OBJECT_CODE_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      taskValue: {
        title: '',
        color: '',
        roles: [],
        required,
        email,
      },
      roleOptions: [],
      formShow: false,
      taskId: null,
    };
  },

  watch: {},

  mounted() {
    this.getTaskById();
  },

  methods: {
    getTaskById() {
      this.formShow = true;

      if (router.currentRoute.params.id) {
        this.taskId = parseInt(router.currentRoute.params.id);

        store
          .dispatch('settings/getTaskById', { id: router.currentRoute.params.id })
          .then((res) => {
            this.taskValue = res.data;
            this.getRoles();
          })
          .catch((error) => {
            console.log(error);
            this.formShow = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        this.getRoles();
      }
    },

    getRoles() {
      store
        .dispatch('settings/getRoles')
        .then((res) => {
          this.roleOptions = res.data.data;
          this.roleOptions = res.data.data.filter((role) => role.name !== 'supermanager');

          this.formShow = false;
        })
        .catch((error) => {
          console.log(error);
          this.formShow = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          });
        });
    },

    colorSelectedHandler(textColor, bgColor) {
      this.taskValue.color = {
        textColor: textColor,
        bgColor: bgColor,
      };
    },

    formSubmitted() {
      this.formShow = true;
      if (!this.taskValue.color.bgColor) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: 'Please select a color in the color palette.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        });
        this.formShow = false;
        return;
      }

      this.$refs.taskRules.validate().then((success) => {
        if (success) {
          if (this.taskId == null) {
            store
              .dispatch('settings/saveTask', this.taskValue)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Task Add Successful',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });

                router.push({ name: 'settings-tasks' });
              })
              .catch((error) => {
                this.formShow = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                  },
                });
              });
          } else {
            store
              .dispatch('settings/updateTask', this.taskValue)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Task Update Successful',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });

                router.push({ name: 'settings-tasks' });
              })
              .catch((error) => {
                this.formShow = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                  },
                });
              });
          }
        } else {
          this.formShow = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
